import { FormControl, InputLabel, Select, MenuItem, Grid, Typography } from '@mui/material';
import { useAppSelector } from 'src/store';

const ChannelPicker = ({ selectedChannel, setSelectedChannel, inputType }) => {
  const { channels } = useAppSelector(state => state.channels);

  const handleChannelChange = (event) => {
    const channelId = event.target.value;
    const channel = channels.find(c => c.id === channelId) || null;
    setSelectedChannel(channel);
  };

  return (
    <FormControl fullWidth>
      <InputLabel id="channels-select-label">Send From</InputLabel>
      <Select
        labelId="channels-select-label"
        value={selectedChannel?.id || ''}
        onChange={handleChannelChange}
        label="Pick sender Oppy"
        required
      >
        {channels
          .filter(channel => channel.channel_email_address || channel.twilio_phone_number)
          .map((channel) => (
            <MenuItem
              key={channel.id}
              value={channel.id}
              disabled={(inputType === 'phone' && !channel.twilio_phone_number) || (inputType === 'email' && !channel.channel_email_address)}
            >
              <Grid container>
                <Grid item xs={12}>
                  <Typography variant="body1" sx={{ fontWeight: 'bold' }}>
                    {channel.chatbot_name}
                  </Typography>
                </Grid>
                {channel.twilio_phone_number && (
                  <Grid item xs={12}>
                    <Typography variant="body2" color="text.secondary">
                      {channel.twilio_phone_number}
                    </Typography>
                  </Grid>
                )}
                {channel.channel_email_address && (
                  <Grid item xs={12}>
                    <Typography variant="body2" color="text.secondary">
                      {channel.channel_email_address}
                    </Typography>
                  </Grid>
                )}
              </Grid>
            </MenuItem>
          ))}
      </Select>
    </FormControl>
  );
};

export default ChannelPicker;
