import React, { useState } from 'react';
import {
  Box,
  Button,
  Checkbox,
  CircularProgress,
  Collapse,
  Divider,
  FormControlLabel,
  IconButton,
  TextField,
  Tooltip,
  Typography,
  MenuItem,
} from '@mui/material';
import {
  ExpandLess,
  ExpandMore,
  Save as SaveIcon,
  HelpOutline as HelpIcon,
  Schedule as ScheduleIcon,
} from '@mui/icons-material';
import { httpClient } from 'src/libs';
import { API_ROUTES } from 'src/constants/routes';
import { useAppSelector } from 'src/store';

interface FollowUpSettings {
  enabled: boolean;
  delay_minutes: number;
  prompt: string;
  respect_business_hours: boolean;
}

interface ConversationMemory {
  follow_up?: Partial<FollowUpSettings>;
}

interface ConversationFollowUpSettingsProps {
  conversationId: string;
}

const INTERVAL_PRESETS = [
  { label: 'Every hour', minutes: 60, tooltip: 'Check conversation hourly for potential updates' },
  { label: 'Every 4 hours', minutes: 240, tooltip: 'Regular check-ins throughout the day' },
  { label: 'Daily', minutes: 1440, tooltip: 'Once per day follow-up check' },
  { label: '2 Days', minutes: 2880, tooltip: 'Check every other day' },
  { label: '3 Days', minutes: 4320, tooltip: 'Check every third day' },
  { label: 'Weekly', minutes: 10080, tooltip: 'Weekly conversation review' },
  { label: 'Custom', minutes: -1, tooltip: 'Set a custom interval in minutes' },
];

const DEFAULT_PROMPT = "Monitor this conversation and determine if a follow-up is needed. Consider: user engagement, unanswered questions, and potential next steps. If appropriate, draft and send a follow-up message.";

const ConversationFollowUpSettings: React.FC<ConversationFollowUpSettingsProps> = ({ conversationId }) => {
  const [isExpanded, setIsExpanded] = useState(false);
  const conversation = useAppSelector(state => state.conversations.entities[conversationId]);
  const [followUpSettings, setFollowUpSettings] = useState<FollowUpSettings>({
    enabled: false,
    delay_minutes: 1440, // Default to Daily
    prompt: DEFAULT_PROMPT,
    respect_business_hours: false,
  });
  const [selectedInterval, setSelectedInterval] = useState(INTERVAL_PRESETS[2]); // Default to Daily
  const [customMinutes, setCustomMinutes] = useState('');
  const [saving, setSaving] = useState(false);
  const [advancedOpen, setAdvancedOpen] = useState(false);

  // Initialize follow-up settings from conversation memory
  React.useEffect(() => {
    if (conversation && conversation.memory) {
      const followUp = conversation.memory.follow_up || {};
      setFollowUpSettings({
        enabled: followUp.enabled ?? false,
        delay_minutes: followUp.delay_minutes ?? 1440,
        prompt: followUp.prompt ?? DEFAULT_PROMPT,
        respect_business_hours: followUp.respect_business_hours ?? false,
      });

      // Set selected interval preset
      const preset = INTERVAL_PRESETS.find(p => p.minutes === followUp.delay_minutes);
      if (preset) {
        setSelectedInterval(preset);
        setCustomMinutes('');
      } else {
        setSelectedInterval(INTERVAL_PRESETS.find(p => p.minutes === -1)); // Custom
        setCustomMinutes(followUp.delay_minutes?.toString() || '');
      }
    }
  }, [conversation]);

  const handleSave = async () => {
    try {
      setSaving(true);
      let delayMinutes = selectedInterval.minutes;
      if (delayMinutes === -1) {
        delayMinutes = parseInt(customMinutes) || 1440;
      }

      const updatedFollowUp: FollowUpSettings = {
        ...followUpSettings,
        delay_minutes: delayMinutes,
      };

      const memoryUpdate: ConversationMemory = {
        follow_up: updatedFollowUp,
      };

      const body = {
        memory: memoryUpdate,
        channel_id: conversation.channel_id,
        id: conversationId,
      };

      const url = `${process.env.REACT_APP_API_V3_URL}${API_ROUTES.private_channel_conversation_merge_memory(conversationId)}`;
      await httpClient.patch(url, body);

      setIsExpanded(false);
    } catch (error) {
      console.error('Failed to update follow-up settings:', error);
    } finally {
      setSaving(false);
    }
  };

  const handleIntervalChange = (event: React.ChangeEvent<{ value: unknown }>) => {
    const minutes = Number(event.target.value);
    const preset = INTERVAL_PRESETS.find(p => p.minutes === minutes);
    if (preset) {
      setSelectedInterval(preset);
      setCustomMinutes('');
    } else {
      setSelectedInterval(INTERVAL_PRESETS.find(p => p.minutes === -1)); // Custom
      setCustomMinutes(minutes.toString());
    }
  };

  const handleCustomMinutesChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setCustomMinutes(event.target.value);
  };

  return (
    <Box mt={2}>
      <Divider >
        <Button
          fullWidth
          onClick={() => setIsExpanded(prev => !prev)}
          startIcon={<ScheduleIcon />}
          endIcon={isExpanded ? <ExpandLess /> : <ExpandMore />}
        >
          Follow-up Settings
        </Button>
      </Divider>
      <Collapse in={isExpanded} timeout="auto" unmountOnExit>
        <Box p={2}>
          <FormControlLabel
            control={
              <Checkbox
                checked={followUpSettings.enabled}
                onChange={(e) => setFollowUpSettings({ ...followUpSettings, enabled: e.target.checked })}
              />
            }
            label="Enable Automated Follow-ups"
          />
          <Box mt={2}>
            <Typography variant="subtitle1" display="flex" alignItems="center">
              Check Interval
              <Tooltip title="How often Oppy should check this conversation for follow-up">
                <IconButton size="small">
                  <HelpIcon fontSize="small" />
                </IconButton>
              </Tooltip>
            </Typography>
            <TextField
              select
              value={selectedInterval.minutes}
              onChange={handleIntervalChange}
              fullWidth
              variant="outlined"
              size="small"
            >
              {INTERVAL_PRESETS.map(interval => (
                <MenuItem key={interval.minutes} value={interval.minutes}>
                  {interval.label}
                </MenuItem>
              ))}
            </TextField>
            {selectedInterval.minutes === -1 && (
              <TextField
                label="Custom Interval (minutes)"
                type="number"
                value={customMinutes}
                onChange={handleCustomMinutesChange}
                fullWidth
                variant="outlined"
                size="small"
                inputProps={{ min: 1 }}
                sx={{ mt: 1 }}
              />
            )}
          </Box>
          <Button
            onClick={() => setAdvancedOpen(prev => !prev)}
            endIcon={advancedOpen ? <ExpandLess /> : <ExpandMore />}
            sx={{ mt: 2 }}
          >
            Advanced Options
          </Button>
          <Collapse in={advancedOpen}>
            <Box mt={2}>
              <FormControlLabel
                control={
                  <Checkbox
                    checked={followUpSettings.respect_business_hours}
                    onChange={(e) => setFollowUpSettings({ ...followUpSettings, respect_business_hours: e.target.checked })}
                  />
                }
                label="Respect Business Hours"
              />
              <TextField
                label="Follow-up Instructions"
                multiline
                rows={4}
                value={followUpSettings.prompt}
                onChange={(e) => setFollowUpSettings({ ...followUpSettings, prompt: e.target.value })}
                fullWidth
                variant="outlined"
                size="small"
                sx={{ mt: 2 }}
              />
            </Box>
          </Collapse>
          <Box display="flex" justifyContent="flex-end" mt={2}>
            <Button
              onClick={handleSave}
              variant="contained"
              color="primary"
              startIcon={saving ? <CircularProgress size={20} /> : <SaveIcon />}
              disabled={saving}
            >
              {saving ? 'Saving...' : 'Save'}
            </Button>
          </Box>
        </Box>
      </Collapse>
    </Box>
  );
};

export default ConversationFollowUpSettings; 
