import React, { useState, useEffect } from 'react';
import { httpClient } from 'src/libs';
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Button,
  Typography,
  Box,
  Tabs,
  Tab,
  TextField,
  LinearProgress,
  Grid,
  InputAdornment,
  IconButton,
} from '@mui/material';
import ChannelPicker from './ChannelPicker';
import AttachFileIcon from '@mui/icons-material/AttachFile'; // Import paperclip icon
import DeleteIcon from '@mui/icons-material/Delete';
import PictureAsPdfIcon from '@mui/icons-material/PictureAsPdf';
import VideocamIcon from '@mui/icons-material/Videocam';
import InsertDriveFileIcon from '@mui/icons-material/InsertDriveFile';
import theme from 'src/themes/oppy-default-theme';
import { getBranding } from 'src/utils/branding';

const BulkConversationCreateDialog = ({ open, handleClose, selectedContacts }) => {
  const [selectedChannel, setSelectedChannel] = useState(null);
  const [inputType, setInputType] = useState('preferred'); // 'preferred', 'email', 'phone'
  const [tabValue, setTabValue] = useState(0);
  const [inputValue, setInputValue] = useState('');
  const [subjectValue, setSubjectValue] = useState('');
  const [emailCount, setEmailCount] = useState(0);
  const [smsCount, setSmsCount] = useState(0);
  const [progress, setProgress] = useState(0);
  const [isSending, setIsSending] = useState(false);
  const [successfulContactsCount, setSuccessfulContactsCount] = useState(0);
  const [failedContacts, setFailedContacts] = useState([]);
  const [showSummary, setShowSummary] = useState(false);
  const [preferredEmailCount, setPreferredEmailCount] = useState(0);
  const [preferredSmsCount, setPreferredSmsCount] = useState(0);
  const [imageUrls, setImageUrls] = useState<string[]>([]);
  const [activeThumbnailIndex, setActiveThumbnailIndex] = useState<number | null>(null);

  // Function to reset state to initial values
  const resetState = () => {
    setSelectedChannel(null);
    setInputType('preferred');
    setTabValue(0);
    setInputValue('');
    setSubjectValue('');
    setImageUrls([]);
    setProgress(0);
    setIsSending(false);
    setSuccessfulContactsCount(0);
    setFailedContacts([]);
    setShowSummary(false);
    // Reset other state variables if needed
  };

  useEffect(() => {
    const emailContacts = selectedContacts.filter(contact => contact.email);
    const smsContacts = selectedContacts.filter(contact => contact.phone_number);
    setEmailCount(emailContacts.length);
    setSmsCount(smsContacts.length);

    // Calculate counts for 'Preferred Method' tab
    let prefEmailCount = 0;
    let prefSmsCount = 0;
    selectedContacts.forEach(contact => {
      let method = contact.preferred_method;
      if (!method) {
        method = contact.email ? 'email' : contact.phone_number ? 'phone' : null;
      }
      if (method === 'email' && contact.email) {
        prefEmailCount++;
      } else if (method === 'phone' && contact.phone_number) {
        prefSmsCount++;
      }
    });
    setPreferredEmailCount(prefEmailCount);
    setPreferredSmsCount(prefSmsCount);
  }, [selectedContacts]);

  useEffect(() => {
    const handleBeforeUnload = (e) => {
      if (isSending) {
        e.preventDefault();
        e.returnValue = '';
        console.log('Attempting to close tab during sending.');
      }
    };
    window.addEventListener('beforeunload', handleBeforeUnload);
    return () => {
      window.removeEventListener('beforeunload', handleBeforeUnload);
    };
  }, [isSending]);

  const handleChange = (event, newValue) => {
    setTabValue(newValue);
    setInputType(newValue === 0 ? 'preferred' : newValue === 1 ? 'phone' : 'email');
  };

  const handleSendMessages = async () => {
    if (!selectedChannel) {
      alert('Please select a channel.');
      return;
    }
    if (!inputValue.trim()) {
      alert('Please enter a message.');
      return;
    }

    setIsSending(true);
    setSuccessfulContactsCount(0);
    setFailedContacts([]);
    const totalContacts = selectedContacts.length;

    const url = `${process.env.REACT_APP_API_V3_URL}/chat/private/messages/post_message`; // Updated URL
    setProgress(0); // Start progress from 0%
    for (let i = 0; i < totalContacts; i++) {
      const contact = selectedContacts[i];
      let method = inputType;
      if (inputType === 'preferred') {
        method = contact.preferred_method || (contact.email ? 'email' : 'phone');
      }
      const toIdentifier = method === 'email' ? contact.email : contact.phone_number;
      if (!toIdentifier) {
        // Skip if contact doesn't have the method
        console.log(`Skipping ${contact.name || contact.first_name || contact.email}: No ${method} available.`);
        continue;
      }
      const data = {
        content: inputValue,
        channel_id: selectedChannel.id,
        to_identifier: toIdentifier,
        to_tag: method === 'email' ? 'email' : 'sms',
        subject: method === 'email' ? subjectValue : undefined,
        media_urls: imageUrls.length > 0 ? imageUrls : undefined,
      };
      try {
        await httpClient.post(url, data, { headers: { "Content-Type": "application/json" } }); // Updated URL usage
        // Increment successful count
        setSuccessfulContactsCount(prevCount => prevCount + 1);
        console.log(`Message sent to ${contact.name || contact.first_name || contact.email} (${toIdentifier}).`);
      } catch (error) {
        // Add to failed contacts with error
        setFailedContacts(prevFailed => [...prevFailed, { contact, error }]);
        console.error(`Error sending to ${contact.name || contact.first_name || contact.email} (${toIdentifier}):`, error);
      }
      setProgress(((i + 1) / totalContacts) * 100); // Update progress
    }
    setIsSending(false);
    setShowSummary(true);
  };

  const handleCloseDialog = () => {
    if (isSending) {
      const confirmClose = window.confirm('Sending is not complete. Do you want to stop sending and close?');
      console.log('Attempting to close dialog during sending.');
      if (!confirmClose) return;
      // If confirmed, reset state
      resetState();
      handleClose();
      return;
    }

    // Check if message has been sent and summary is showing
    if (showSummary) {
      resetState();
      handleClose();
      return;
    }

    // If message has been populated but not sent
    if (inputValue || subjectValue || imageUrls.length > 0) {
      const confirmDiscard = window.confirm('You have unsent changes. Do you really want to discard them and close?');
      if (!confirmDiscard) return;
    }

    // Reset state and close dialog
    resetState();
    handleClose();
  };

  // Updated media upload function to handle different file types
  const handleMediaUpload = () => {
    window.cloudinary.openUploadWidget(
      {
        cloudName: process.env.REACT_APP_CLOUDINARY_CLOUD_NAME,
        uploadPreset: process.env.REACT_APP_CLOUDINARY_UPLOAD_PRESET,
        multiple: true,
        resourceType: 'auto',
        sources: ['local', 'camera'], // Only allow camera and file upload
        clientAllowedFormats: [
          'png', 'jpg', 'jpeg', 'gif', 'bmp', 'ico',         // Images
          'mp4', 'mov', 'avi', 'wmv', 'flv', 'webm', 'mkv', // Videos
          'mp3', 'wav', 'm4a', 'flac',                       // Audios
          'pdf'                                              // Documents
        ],
        maxFileSize: 100 * 1024 * 1024, // Limit to 100 MB
        cropping: false,
        showPoweredBy: false, // Hide Cloudinary logo
        styles: {
          palette: {
            window: theme.palette.background.default,          // Use Oppy theme background
            tabIcon: theme.palette.primary.main,               // Primary color for icons
            menuIcons: theme.palette.secondary.main,           // Secondary color for menu icons
            textDark: theme.palette.text.primary,              // Primary text color
            textLight: '#fff',           // Secondary text color
            link: theme.palette.primary.main,                  // Primary color for links
            inactiveTabIcon: theme.palette.text.disabled,      // Disabled text color
            error: theme.palette.error.main,                   // Error color
            inProgress: theme.palette.info.main,               // Info color
            complete: theme.palette.success.main,              // Success color
            sourceBg: theme.palette.background.paper,          // Background for source area
          },
          fonts: {
            default: theme.typography.fontFamily,              // Use Oppy default font
            "'Poppins', sans-serif": "https://fonts.googleapis.com/css?family=Poppins",
          },
          logo: getBranding('logo'),                           // Use Oppy logo
        },
        // Optional: Localization
        // language: "en",
      },
      (error, result) => {
        if (!error && result.event === 'success') {
          const { public_id, resource_type, format, type } = result.info;

          // Introduce new variables for adjusted values
          let transformation = '';
          let adjusted_resource_type = resource_type;
          let adjusted_format = format;

          if (resource_type === 'image') {
            // Image transformations
            transformation = 'c_limit,w_1600,h_1600,q_auto:eco,f_auto';
          } else if (resource_type === 'video') {
            // Video transformations
            transformation = 'c_limit,w_1280,h_720,q_auto:eco,f_auto';
          } else if (resource_type === 'raw' && format === 'pdf') {
            // PDF transformations (generate preview image)
            transformation = 'c_limit,w_800,q_auto:eco,f_auto,page=1';

            // Adjust resource_type and format using new variables
            adjusted_resource_type = 'image';
            adjusted_format = 'jpg';
          } else {
            // For other types (e.g., audio), no transformation
            transformation = '';
          }

          // Use adjusted_resource_type and adjusted_format in the URL
          const transformedUrl = transformation
            ? `https://res.cloudinary.com/${process.env.REACT_APP_CLOUDINARY_CLOUD_NAME}/${adjusted_resource_type}/upload/${transformation}/${public_id}.${adjusted_format}`
            : result.info.secure_url;

          // Log the transformed URL
          console.log('Transformed media URL:', transformedUrl);

          // Update state with transformed URL
          setImageUrls((prev) => [...prev, transformedUrl]);
        } else if (error) {
          console.error('Upload error:', error); // Log any errors
        }
      }
    );
  };

  // Add handleDeleteMedia function
  const handleDeleteMedia = (index) => {
    setImageUrls(prev => prev.filter((_, i) => i !== index));
    console.log(`Deleted media at index ${index}`);
  };

  return (
    <Dialog open={open} onClose={handleCloseDialog} fullWidth maxWidth="sm">
      <DialogTitle>
        Send Bulk Message
        <Typography variant="subtitle1" component="div">
          Starts conversations with contacts. Replies handled by your chosen Oppy. Monitor in conversations tab.
        </Typography>
      </DialogTitle>
      <DialogContent>
        <Box sx={{ mt: 2 }}>
          <ChannelPicker
            selectedChannel={selectedChannel}
            setSelectedChannel={setSelectedChannel}
            inputType={inputType}
          />
          <Typography variant="body2" sx={{ mt: 2 }}>
            {`Total Contacts Selected: ${selectedContacts.length}`}
          </Typography>
          <Typography variant="body2">
            {`Contacts with Email: ${emailCount}`}
          </Typography>
          <Typography variant="body2">
            {`Contacts with SMS: ${smsCount}`}
          </Typography>
          <Tabs
            value={tabValue}
            onChange={handleChange}
            variant="fullWidth"
            sx={{ mt: 2 }}
          >
            <Tab label="Preferred Method" />
            <Tab label="SMS" disabled={smsCount === 0} />
            <Tab label="Email" disabled={emailCount === 0} />
          </Tabs>

          {/* Shared Input Fields */}
          <Box sx={{ p: 2 }}>
            {(selectedContacts.some(contact => contact.email) || selectedContacts.some(contact => contact.phone_number)) ? (
              <>
                {/* Conditionally render Subject input */}
                {inputType !== 'phone' && (
                  <TextField
                    label="Subject"
                    fullWidth
                    value={subjectValue}
                    onChange={(e) => setSubjectValue(e.target.value)}
                    sx={{ mb: 2 }}
                  />
                )}

                {/* Message Input */}
                <TextField
                  multiline
                  minRows={5}
                  placeholder="Message"
                  fullWidth
                  value={inputValue}
                  onChange={(e) => setInputValue(e.target.value)}
                  InputProps={{
                    startAdornment: (
                      <InputAdornment position="start">
                        <IconButton onClick={handleMediaUpload}>
                          <AttachFileIcon />
                        </IconButton>
                      </InputAdornment>
                    ),
                  }}
                />

                {/* Media Thumbnails */}
                <Box
                  sx={{
                    mt: 2,
                    backgroundColor: '#f5f5f5',
                    p: 2,
                  }}
                >
                  <Box sx={{ display: 'flex', flexWrap: 'wrap' }}>
                    {imageUrls.map((url, index) => (
                      <Box
                        key={index}
                        sx={{
                          position: 'relative',
                          width: 120,
                          height: 120,
                          mr: 1,
                          mb: 1,
                        }}
                        onMouseEnter={() => setActiveThumbnailIndex(index)}
                        onMouseLeave={() => setActiveThumbnailIndex(null)}
                        onClick={() => setActiveThumbnailIndex(index)}
                      >
                        {/* Render media based on file type */}
                        {(() => {
                          if (/\.(png|jpg|jpeg|gif)$/i.test(url)) {
                            // Image files
                            return (
                              <img
                                src={url}
                                alt={`Uploaded ${index}`}
                                style={{ width: '100%', height: '100%', objectFit: 'contain' }}
                              />
                            );
                          } else if (/\.(mpmov|avi|wmv|flv|webm|mkv)$/i.test(url)) {
                            // Video files
                            return (
                              <video
                                width="100%"
                                height="100%"
                                style={{ objectFit: 'contain' }}
                                controls
                              >
                                <source src={url} type="video/mp4" />
                                Your browser does not support the video tag.
                              </video>
                            );
                          } else if (/\.(pdf)$/i.test(url)) {
                            // PDF files
                            return (
                              <Box
                                sx={{
                                  width: '100%',
                                  height: '100%',
                                  display: 'flex',
                                  alignItems: 'center',
                                  justifyContent: 'center',
                                  backgroundColor: '#e0e0e0',
                                }}
                              >
                                <PictureAsPdfIcon sx={{ fontSize: 48 }} />
                              </Box>
                            );
                          } else {
                            // Other files
                            return (
                              <Box
                                sx={{
                                  width: '100%',
                                  height: '100%',
                                  display: 'flex',
                                  alignItems: 'center',
                                  justifyContent: 'center',
                                  backgroundColor: '#e0e0e0',
                                }}
                              >
                                <InsertDriveFileIcon sx={{ fontSize: 48 }} />
                              </Box>
                            );
                          }
                        })()}
                        {/* Delete icon on hover or tap */}
                        {activeThumbnailIndex === index && (
                          <Box
                            sx={{
                              position: 'absolute',
                              top: 4,
                              right: 4,
                            }}
                          >
                            <IconButton
                              size="small"
                              sx={{ bgcolor: 'rgba(0,0,0,0.5)', color: 'white' }}
                              onClick={() => handleDeleteMedia(index)}
                            >
                              <DeleteIcon fontSize="small" />
                            </IconButton>
                          </Box>
                        )}
                      </Box>
                    ))}
                  </Box>
                </Box>

                {/* Display counts */}
                {inputType === 'preferred' && (
                  <Typography variant="body2" sx={{ mt: 2 }}>
                    {`Will send to ${preferredEmailCount} contacts via Email and ${preferredSmsCount} contacts via SMS.`}
                  </Typography>
                )}
                {inputType === 'phone' && (
                  <Typography variant="body2" sx={{ mt: 2 }}>
                    {`Will send to ${smsCount} contacts via SMS.`}
                  </Typography>
                )}
                {inputType === 'email' && (
                  <Typography variant="body2" sx={{ mt: 2 }}>
                    {`Will send to ${emailCount} contacts via Email.`}
                  </Typography>
                )}
              </>
            ) : (
              <Typography>No contacts available for the selected method.</Typography>
            )}
          </Box>

          {isSending && (
            <Box sx={{ width: '100%', mt: 2 }}>
              <LinearProgress variant="determinate" value={progress} />
              <Typography variant="body2" color="textSecondary" align="center">
                {`Sending messages... ${Math.round(progress)}%`}
              </Typography>
            </Box>
          )}
          {showSummary && (
            <Box sx={{ mt: 2 }}>
              <Typography variant="h6">Summary</Typography>
              <Typography>{`Successfully sent to ${successfulContactsCount} contacts.`}</Typography>
              {failedContacts.length > 0 && (
                <>
                  <Typography>{`Failed to send to ${failedContacts.length} contacts:`}</Typography>
                  {failedContacts.map(({ contact, error }, index) => (
                    <Typography key={index} color="error">
                      {`${contact.name || contact.first_name || contact.email}: ${error.message}`}
                    </Typography>
                  ))}
                </>
              )}
            </Box>
          )}
        </Box>
      </DialogContent>
      <DialogActions>
        {showSummary ? (
          <Button onClick={handleCloseDialog}>Close</Button>
        ) : (
          <>
            <Button onClick={handleCloseDialog} disabled={isSending}>Cancel</Button>
            <Button
              onClick={handleSendMessages}
              disabled={!inputValue || !selectedChannel || isSending}
            >
              {isSending ? 'Sending...' : 'Send'}
            </Button>
          </>
        )}
      </DialogActions>
    </Dialog>
  );
};

export default BulkConversationCreateDialog;
