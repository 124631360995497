import React, { useEffect, useState, useCallback } from 'react';
import { Box, Button, CircularProgress } from '@mui/material';
import NoteItem from './NoteItem';
import NoteForm from './NoteForm';
import notesService from 'src/services/notes.service';
import { Note } from 'src/features/notes/notes.slice';

interface NotesListProps {
  ownerId: string;
  ownerType: string;
}

const NotesList: React.FC<NotesListProps> = ({ ownerId, ownerType }) => {
  const [notes, setNotes] = useState<Note[]>([]);
  const [loading, setLoading] = useState<boolean>(true);
  const [error, setError] = useState<string | null>(null);
  const [showForm, setShowForm] = useState<boolean>(false);

  const fetchNotes = async () => {
    setLoading(true);
    try {
      const params = { owner_id: ownerId, owner_type: ownerType };
      const response = await notesService.getNotes(params);
      console.log('Fetched notes:', response.data.notes);
      setNotes(response.data.notes);
      if (response.data?.notes?.length === 0) {
        setShowForm(true);
      }
    } catch (err) {
      console.error('Error fetching notes:', err);
      setError('Error fetching notes');
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchNotes();
    // Clean up when component unmounts
    return () => {
      setNotes([]);
      setLoading(false);
      setError(null);
    };
  }, [ownerId, ownerType]);

  const handleAddNote = () => {
    setShowForm(true);
  };

  const handleSaveNote = async (noteData: any) => {
    try {
      const completeNoteData = { ...noteData, owner_id: ownerId, owner_type: ownerType };
      const response = await notesService.createNote(completeNoteData);
      
      const createdNote = response.data.note || response.data;
      console.log('Note created:', createdNote);
      
      setNotes([createdNote, ...(notes || [])]);
      setShowForm(false);
    } catch (err) {
      console.error('Error creating note:', err);
      setError('Error creating note');
    }
  };

  const handleUpdateNote = async (noteId: string, updatedData: any) => {
    try {
      const response = await notesService.updateNote(noteId, updatedData);
      console.log('Note updated:', response.data.note);
      setNotes((prevNotes) =>
        prevNotes?.map((note) => (note.id === noteId ? response.data.note : note))
      );
    } catch (err) {
      console.error('Error updating note:', err);
      setError('Error updating note');
    }
  };

  const handleDeleteNote = async (noteId: string) => {
    if (!window.confirm('Are you sure you want to delete this note?')) {
      return;
    }
    try {
      await notesService.deleteNote(noteId);
      console.log('Note deleted:', noteId);
      setNotes((prevNotes) => prevNotes.filter((note) => note.id !== noteId));
    } catch (err) {
      console.error('Error deleting note:', err);
      setError('Error deleting note');
    }
  };

  const handleCancelForm = useCallback(() => {
    setShowForm(false);
  }, []);

  return (
    <Box>
      <Box display="flex" justifyContent="flex-end" marginBottom="1rem">
        {showForm ? (
          <Button variant="outlined" onClick={() => setShowForm(false)}>
            Hide Form
          </Button>
        ) : (
          <Button variant="contained" color="primary" onClick={handleAddNote}>
            Add Note
          </Button>
        )}
      </Box>
      {showForm && (
        <NoteForm
          onSave={handleSaveNote}
          onCancel={handleCancelForm}
          initialValues={{ owner_id: ownerId, owner_type: ownerType }}
        />
      )}
      {loading && <CircularProgress />}
      {error && <p>{error}</p>}
      {notes?.map((note) => (
        <NoteItem
          key={note.id}
          note={note}
          onUpdate={handleUpdateNote}
          onDelete={handleDeleteNote}
        />
      ))}
    </Box>
  );
};

export default NotesList; 
