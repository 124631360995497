import React from 'react';
import { Box, Typography } from '@mui/material';
import { getBranding } from '../../utils/branding';

const TitleSlide: React.FC = () => {
  const logoUrl = getBranding('oppy_word_mark_yellow');

  return (
    <Box
      sx={{
        height: 'calc(100vh - 115px)',
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        alignItems: 'center',
        backgroundColor: 'black',
        color: 'white',
        position: 'relative',
      }}
    >
      <Box
        component="img"
        src={logoUrl}
        alt="Oppy Logo"
        sx={{
          width: '50%',
          maxWidth: '400px',
          height: 'auto',
        }}
      />
      <Box
        sx={{
          position: 'absolute',
          bottom: 0,
          left: 0,
          right: 0,
          padding: '1rem',
          backgroundColor: 'rgba(255, 255, 255, 0.1)',
          backdropFilter: 'blur(5px)',
        }}
      >
      </Box>
    </Box>
  );
};

export default TitleSlide;
