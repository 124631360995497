import React, { useState, useEffect } from 'react';
import { Box } from '@mui/material';
import { useAppSelector } from 'src/store';
import ChannelHeader from './ChannelHeader';
import { selectChannelById } from 'src/features/account/channels.slice';
import FAQWrapper from './FAQWrapper';

const Channel = ({ channelId }) => {
  const channel = useAppSelector(state => selectChannelById(state, channelId));

  const mapChannelFAQsToFAQType = (channelFAQs) => {
    return channelFAQs.map(faq => ({
      ...faq,
      type: faq.metadata?.type || 'general'
    }));
  };

  const handleCreateFAQ = (newFAQ) => {
    console.log('Creating new FAQ:', newFAQ);
  };

  const handleUpdateFAQ = (updatedFAQ) => {
    console.log('Updating FAQ:', updatedFAQ);
  };

  const handleDeleteFAQ = (id) => {
    console.log('Deleting FAQ with id:', id);
  };

  if (!channel) {
    return <Box>Loading...</Box>;
  }

  return (
    <Box sx={{ 
      width: '100%', 
      height: '100%', 
      overflow: 'auto', 
      padding: 0, 
      display: 'flex', 
      justifyContent: 'center' 
    }}>
        <ChannelHeader channelId={channelId} />
        {/* <Box sx={{ maxWidth: '1200px', margin: '0 auto', padding: '24px' }}>
          <FAQWrapper />
        </Box> */}
    </Box>
  );
};

export default Channel;
