import { useContext, useEffect, useState } from 'react';
import { useAppSelector, useAppDispatch } from 'src/store';
import { useNavigate } from 'react-router-dom';
import { useSnackbar } from 'notistack';
import { 
  fetchConversationWithMessages, 
  removeConversationById,
  removeMessageFromConversation
} from 'src/features/account/conversations.slice';
import { 
  getContact,
  removeContactState,
  selectAllContacts, 
} from 'src/features/account/contacts.slice';
import { 
  fetchChannel, 
} from 'src/features/account/channels.slice';
import useAuth from 'src/hooks/useAuth';
import { PusherContext } from 'src/services/auth.service'; 
import LiveTranscriptionDialog from '../Lottie/LiveTranscriptionDialog';
import { getMessage } from 'src/features/account/messages.slice';
import IconButton from '@mui/material/IconButton'; // For snackbar action buttons
import MarkUnreadChatAltIcon from '@mui/icons-material/MarkUnreadChatAlt'; // Icon for snackbar action
import CloseIcon from '@mui/icons-material/Close'; // Icon to close snackbar
import AuthService from 'src/services/auth.service';

const AccountNotifications = () => {
  const { isLoggedIn, authLoading } = useAuth();
  const { user } = useAppSelector(state => state.user);
  const [onlineUsers, setOnlineUsers] = useState([]);
  const { enqueueSnackbar, closeSnackbar } = useSnackbar(); // Destructure closeSnackbar
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const pusher = useContext(PusherContext);
  const [pusherSubscriptionSucceeded, setPusherSubscriptionSucceeded] = useState(false);
  // const handlePusherEvent = useHandlePusherEvents();
  const [transcriptionDialogOpen, setTranscriptionDialogOpen] = useState(false);
  const [latestTranscriptionChunk, setLatestTranscriptionChunk] = useState(null);
  const [transcriptionMessages, setTranscriptionMessages] = useState([]);

  // Snackbar action for new messages
  const snackbarNewMessageAction = (key, message) => (
    <>
      <IconButton onClick={() => { navigate(`/conversations?conversation_id=${message.conversation_id}&channel_id=${message.channel_id}&message_id=${message.id}`); }}>
        <MarkUnreadChatAltIcon sx={{ color: 'white' }} />
      </IconButton>
      <IconButton onClick={() => closeSnackbar(key)}> {/* Use closeSnackbar here */}
        <CloseIcon />
      </IconButton>
    </>
  );

  // Function to handle actions when a new message is received
  const newMessageActions = (conversation, message) => {
    console.log('newMessageActions called with conversation and message:', conversation, message);
    const senderEntity = conversation?.participating_entities?.find(entity => entity?.id === message?.sender_id);
    console.log('senderEntity:', senderEntity);

    const truncatedMessageContent = message.content.length > 100 ? message.content.substring(0, 100) + '...' : message.content;
    const senderString = senderEntity?.name ? `${senderEntity.name}: ` : '';
    const notiMessage = `${senderString}${truncatedMessageContent}`;
    console.log('Notification message:', notiMessage);

    // Play appropriate sound based on sender role
    if (senderEntity?.role === 'function') {
      console.log('Playing keyboard sound');
      new Audio('/audio/keyboard.mp3').play();
    } else {
      console.log('Playing message received sound');
      new Audio("/audio/message_received.mp3").play();
    }

    // Display snackbar notification
    enqueueSnackbar(notiMessage, {
      hideIconVariant: true,
      autoHideDuration: 4000,
      action: key => snackbarNewMessageAction(key, message)
    });
  }

  // for a new onlineUser add a enqueueSnackbar
  const newOnlineUserSnackbar = (onlineUser) => {
    enqueueSnackbar(`${onlineUser.name} is online`, { variant: 'success', anchorOrigin: { vertical: 'bottom', horizontal: 'left' } });
  }

  useEffect(() => {
    if (onlineUsers.length > 0) {
      newOnlineUserSnackbar(onlineUsers[0]);
    }
  }, [onlineUsers]);

  // Function to play sound for message creation
  const playSoundForMessageCreation = (senderRole) => {
    if (senderRole === 'function') {
      console.log('Playing keyboard sound');
      new Audio('/audio/keyboard.mp3').play();
    } else {
      console.log('Playing message received sound');
      new Audio("/audio/message_received.mp3").play();
    }
  };

  // Function to show snackbar notification
  const showSnackbarNotification = (notification) => {
    if (!notification || !notification.notification) return; // Check if notification exists
    const { title, body } = notification.notification;
    if (title && body) {
      const notiMessage = `${title}: ${body}`;
      console.log('Notification message:', notiMessage);

      enqueueSnackbar(notiMessage, {
        hideIconVariant: true,
        autoHideDuration: 4000,
        action: key => snackbarNewMessageAction(key, { title, body })
      });
    }
  };

  // Function to handle pusher events with debouncing
  // const handlePusherEvent = debounce((eventName, data) => {
  //   console.log(`Received event ${eventName}:`, data);

  //   if (eventName === 'message_created') {
  //     // Play sound for message creation
  //     const senderRole = data.sender_role; // Ensure sender_role is part of the data
  //     playSoundForMessageCreation(senderRole);

  //     // Show notification if notification payload exists
  //     const notification = data.message?.notification;
  //     showSnackbarNotification(notification);
  //   }

  //   if (eventName === 'message_created' || eventName === 'message_updated') {
  //     // Fetch the message
  //     dispatch(getMessage({ messageId: data.message.id }));
  //   }

  //   if (eventName === 'message_deleted') {
  //     console.log('message_deleted - pusher_data:', data);
  //     dispatch(removeMessageFromConversation({ conversationId: data.message.conversation_id, messageId: data.message.id }));
  //   }
  // }, 1000); // Debounce by 1 second

  useEffect(() => {
    if (isLoggedIn && user && !pusherSubscriptionSucceeded) {
      console.log('AccountNotifications - pusherSignin is about to be called');
      AuthService.pusherSignin();
      setPusherSubscriptionSucceeded(true);
      pusher.bind('pusher:subscription_succeeded', () => {
        console.log('pusher:subscription_succeeded! setting state');
        setPusherSubscriptionSucceeded(true);
        enqueueSnackbar('Live connection succeeded!', { variant: 'success', anchorOrigin: { vertical: 'bottom', horizontal: 'left' } });
      });
    }
    if (isLoggedIn && user && pusherSubscriptionSucceeded) {
      const channel = pusher.subscribe('presence-account-' + user.account.uuid);

      const bindEvents = () => {
        ['channel_created', 'channel_updated', 'channel_deleted'].forEach(eventName => {
          channel.bind(eventName, (data) => {
            console.log(`account_notifications ${eventName} - pusher_data:`, data);
            if (eventName === 'channel_created' || eventName === 'channel_updated') {
              const channelId = data.channel.id as string; // Ensure channelId is a string
              dispatch(fetchChannel(channelId));
            }
          });
        });

        ['conversation_created', 'conversation_updated', 'conversation_deleted'].forEach(eventName => {
          channel.bind(eventName, (data) => {
            if (eventName === 'conversation_created' || eventName === 'conversation_updated') {
              console.log(`Fetching updated conversation for ${eventName}`);
              dispatch(fetchConversationWithMessages({ conversationId: data.conversation.id, messagesLimit: 0 }));
            }
            if (eventName === 'conversation_deleted') {
              console.log(`Deleting conversation for ${eventName}`);
              dispatch(removeConversationById(data.conversation.id));
            }
          });
        });
        
        ['message_created', 'message_updated', 'message_deleted'].forEach(eventName => {
          channel.bind(eventName, (data) => {

            if (eventName === 'message_created' || eventName === 'message_updated') {
              // Fetch the message
              dispatch(getMessage({ messageId: data.message.id }));
            }

            if (eventName === 'message_created') {
              // Play sound for message creation
              const senderRole = data?.role; // Ensure sender_role is part of the data
              playSoundForMessageCreation(senderRole);

              // Show notification if notification payload exists
              const notification = data?.message?.notification;
              // if sender role is function, don't show notification
              if (senderRole !== 'function') {
                showSnackbarNotification(notification);
              }
            }

            if (eventName === 'message_deleted') {
              console.log('message_deleted - pusher_data:', data);
              dispatch(removeMessageFromConversation({ conversationId: data.message.conversation_id, messageId: data.message.id }));
            }
          });
        });

        ['transcript_updated'].forEach(eventName => {
          console.log('eventName', eventName);
          channel.bind(eventName, (data) => {
            console.log('transcript_updated - pusher_data:', data);
            setLatestTranscriptionChunk(data.transcription); // Pass the new chunk
            if (!transcriptionDialogOpen) {
              setTranscriptionDialogOpen(true);
            }
          });
        });
        ['contact_created', 'contact_updated', 'contact_deleted'].forEach(eventName => {
          channel.bind(eventName, (data) => {
            console.log('contact_created - pusher_data:', data);
            console.log(`${eventName} - pusher_data:`, data);
            if (data.contact && data.contact.id) {
              if (eventName === 'contact_created' || eventName === 'contact_updated') {
                dispatch(getContact(data.contact.id));
              } else if (eventName === 'contact_deleted') {
                console.log(`Removing contact for ${eventName}`);
                dispatch(removeContactState(data.contact.id));
              }
            }
          });
        });
        ['entity_created', 'entity_updated', 'entity_deleted'].forEach(eventName => {
          channel.bind(eventName, (data) => {
            console.log('entity_created - pusher_data:', data);
            // setLatestChannelPusherEvent({ eventName, data });
          });
        });
        ['faq_created', 'faq_updated', 'faq_deleted'].forEach(eventName => {
          channel.bind(eventName, (data) => {
            console.log('faq updated - pusher_data:', data);
            // setLatestChannelPusherEvent({ eventName, data });
          });
        });
      };

      bindEvents();

      return () => {
        channel.unbind_all(); // Clean up: unbind all events when the component unmounts or data changes
        channel.unsubscribe();
      };
    }
  }, [isLoggedIn, user, pusherSubscriptionSucceeded, authLoading, dispatch]);

  return (
    <>
      <LiveTranscriptionDialog
        open={transcriptionDialogOpen}
        onClose={() => setTranscriptionDialogOpen(false)}
        latestTranscriptionChunk={latestTranscriptionChunk} // Updated prop
      />
    </>
  );
}

export default AccountNotifications;
